'use strict';

var baseClientSideValidation = require('base/components/clientSideValidation');
const scrollAnimate = require('base/components/scrollAnimate');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event, highlightErrors) {
    var valid = true;
    if (this && this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (highlightErrors && !this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
        let $firstErrorElement = $('.is-invalid:first').closest('.form-group');
        if ($firstErrorElement && $firstErrorElement.length > 0) {
            scrollAnimate($firstErrorElement);
        }
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.form-group.is-invalid').removeClass('is-invalid');
    $(form).find('.selectric-form-control.is-invalid').removeClass('is-invalid');
}

module.exports = $.extend(baseClientSideValidation, {
    invalid: function () {
        $('body').on('invalid', 'form input, form select', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                if ($(this).parent().hasClass('selectric-hide-select')) {
                    $(this).closest('.form-group').find('.selectric-wrapper').addClass('is-invalid');
                } else {
                    $(this).closest('.form-group').addClass('is-invalid');
                    $(this).addClass('is-invalid');
                }

                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if (this.validity.typeMismatch && $(this).data('type-mismatch')) {
                    validationMessage = $(this).data('type-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (
                    ((this.maxLength > -1 && this.value.length > this.maxLength) || (this.minLength > -1 && this.value.length < this.minLength))
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }

            let fieldName = $(this).attr('name');
            if (fieldName && fieldName.indexOf('passportExpDate') > -1) {
                let dateStr = $(this).val();
                if (dateStr) {
                    let dateArr = dateStr.split('/');
                    let passportExpDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
                    let minDate = new Date($(this).attr('data-flight-date'));
                    minDate.setMonth(minDate.getMonth() + 6);
                    if (passportExpDate.getTime() <= minDate.getTime()) {
                        if ($(this).parent().hasClass('selectric-hide-select')) {
                            $(this).closest('.form-group').find('.selectric-wrapper').addClass('is-invalid');
                        } else {
                            $(this).closest('.form-group').addClass('is-invalid');
                            $(this).addClass('is-invalid');
                        }
                        $(this).parents('.form-group').find('.invalid-feedback').text($(this).attr('data-range-error'));
                        this.setCustomValidity($(this).attr('data-range-error'));
                    } else {
                        $(this).closest('.form-group').removeClass('is-invalid');
                        $(this).closest('.form-group').find('input').removeClass('is-invalid');
                    }
                }
            }
        });
    },
    functions: {
        clearForm: clearForm,
        disableUntilValid: function (form, button) {
            var $form = $(form);
            var $button = $(button);
            var checkForm = function () {
                var formValidate = validateForm.call($(form).get(0), event || null, false);
                if (formValidate) {
                    $button.removeAttr('disabled');
                } else {
                    $button.attr('disabled', 'disabled');
                }
            };
            $form.on('change input', checkForm);
            checkForm();
        },
        validateForm: function (form, event) {
            return validateForm.call($(form).get(0), event || null, true);
        }
    }
});
