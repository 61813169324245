'use strict';

require('selectric');
var clientSideValidation = require('../components/clientSideValidation');
var cleave = require('./cleave');

/**
 * @desc Updates CSS class on text fields to reflect whether they are populated with input or not
 * @param {Object} $el form field element
 * @param {boolean} loaded form
 */
var pollInput = function ($el) {
    if (($el.val() || $el.is(':checked')) && !$el.hasClass('is-invalid')) {
        $el.addClass('filled');
        if (!$el.is('select')) {
            $el.parents('.form-group').addClass('form-group-filled');
        }
    } else {
        $el.removeClass('filled');
        $el.parents('.form-group').removeClass('form-group-filled');
    }
};

function validateSelect(select) {
    if (select.val() === '' || !select.val()) {
        select.trigger('invalid');
        select.selectric('refresh');
        select.parents('.form-group').find('.invalid-feedback').show();
        select.parents('.form-group').find('.selectric-wrapper').addClass('selectric-is-invalid');
        select.parents('.form-group').find('.selectric-wrapper').addClass('is-invalid');
        select.parents('.form-group').find('.custom-select').addClass('is-invalid');
    } else {
        select.parents('.form-group').find('.invalid-feedback').hide();
        select.parents('.form-group').find('.selectric-wrapper').removeClass('selectric-is-invalid');
        select.parents('.form-group').find('.selectric-wrapper').removeClass('is-invalid');
        select.parents('.form-group').find('.custom-select').removeClass('is-invalid');
    }
}

/**
 * Checks if the provided jQuery element is a datepicker input field.
 *
 * @param {jQuery} $el The element to check.
 * @returns {boolean} true if the element is a datepicker field, false otherwise.
 */
function isDatePicker($el) {
    return $el.hasClass('datepicker-input') || $el.hasClass('datepicker-input-container');
}

/**
 * @listener
 * @desc Fires the pollInput function once per textual input field every time a change is made
 */
function validateField() {
    $('body').on('blur focusout', 'input, textarea', function () {
        let $this = $(this);

        if (!isDatePicker($this)) {
            $(this).trigger('invalid');
        }
    });
}

/**
 * Clears the validation errors of an invalidated form element after a change occurs
 */
function clearErrorsOnChange() {
    $('body').on('input focus', ':input, .form-control, .form-group', function () {
        let $this = $(this);
        if ($this.hasClass('is-invalid') && !isDatePicker($this)) {
            $this.removeClass('is-invalid');
            $this.closest('.invalid-feedback').remove();
        }
    });
}

$(document).on('keyup keypress input propertychange focus blur change', 'input, select, textarea', function (e) {
    e.stopPropagation();
    pollInput($(this));
});

// fire the pollInput function once initially on page load
$(document).find('input, textarea, select').each(function () {
    pollInput($(this));
});

$(document).on('click', function () {
    if ($('.selectric-open').is(':visible')) {
        var select = $('.selectric-open').find('.custom-select');
        validateSelect(select);
    }
});
$(document).on('selectric-select', '.custom-select', function () {
    validateSelect($(this));
});

$(document).on('change', 'select', function (e) {
    let $select = $(e.target);
    let $firstOption = $select.find('option:first');
    if ($firstOption.hasClass('hidden')) {
        $firstOption.remove();
        $select.closest('.selectric-wrapper .selectric-items li:first').remove();
        $select.selectric('refresh');
    }
    validateSelect($(this));
});

$('select').selectric({
    nativeOnMobile: true
});

$('body').on('loginmodal:ready', function () {
    cleave.handleOnlyNumbers();
    clientSideValidation.functions.disableUntilValid($('.login'), $('button.popup-login'));
    setTimeout(function () {
        $($('.login').find('input:visible').get(0)).focus();
    }, 750);
});

$('body').on('click', '.no-action', function (e) {
    e.preventDefault();
});

clientSideValidation.functions.disableUntilValid($('#dwfrm_billing'), $('button.submit-billing'));
clientSideValidation.functions.disableUntilValid($('#personalInfoForm'), $('button.submit-personalinfo'));
clientSideValidation.functions.disableUntilValid($('#dwfrm_profile'), $('button.submit-profile'));

clearErrorsOnChange();
validateField();

module.exports = function () {
    cleave.handleOnlyNumbers();
};
