'use strict';

var focusHelper = require('base/components/focus');
const utils = require('../util/utils');
const debounce = require('lodash/debounce');
const scrollAnimate = require('base/components/scrollAnimate');
const cleave = require('../components/cleave');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $el && $el.length > 0 && $('.quantity-select', $el).length > 0 ? $('.quantity-select', $el) : $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateExtraRoom(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-extra-room').empty().html(optionsHtml);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    var carousel = $productContainer.find('.carousel');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');
    $(carousel).empty().append('<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' + carouselId + '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('prev') + '</span></a><a class="carousel-control-next" href="#' + carouselId + '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' + $(carousel).data('next') + '</span></a>');
    for (var i = 0; i < imgs.length; i++) {
        $('<div class="carousel-item"><img src="' + imgs[i].url + '" class="d-block img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel).find('.carousel-inner'));
        $('<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""></li>').appendTo($(carousel).find('.carousel-indicators'));
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

function showInviteColleagueForm(response, $productContainer) {
    $('.js-invite-form-wrapper', $productContainer).html(response.product.inviteForm);
    cleave.handleOnlyNumbers();
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer).not('.js-related-products-container .prices .price')
            : $('.prices .price').not('.js-related-products-container .prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    showInviteColleagueForm(response, $productContainer);
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer, isOption) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                if (!isOption) {
                    updateExtraRoom(data.product.extraRoomHtml, $productContainer);
                }
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $('body').trigger('product:hilaAddToCart');
                $productContainer.find('select').selectric('refresh');
            },
            error: function () {
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

function getColleagueInviteData() {
    let inviteColleagueType = $('.js-invite-option-select').find('option:checked').val();
    let formData = null;

    if (inviteColleagueType) {
        formData = {
            inviteType: inviteColleagueType
        };
        if (inviteColleagueType === 'specified') {
            formData.colleagueNo = $('#invite-colleagueNo').val();
            formData.colleagueEmail = $('#invite-colleagueEmail').val();
            formData.colleaguePhone = $('#invite-colleaguePhone').val();
        }
    }

    return formData;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.grand-total').empty().append(data.totals.grandTotal);

    if (data.items.length > 0) {
        $('.pdp-totals').attr('data-mode', '');
        if (!data.valid.error) {
            $('.pdp-totals .btn-purchase a').removeClass('disabled');
        }
    } else {
        $('.pdp-totals').attr('data-mode', 'empty');
        $('.pdp-totals .btn-purchase a').addClass('disabled');
    }

    if (data.groupedItems) {
        $('.row.main-product').remove();
        if (data.groupedItems.main) {
            let html = '';
            data.groupedItems.main.forEach(function (pli) {
                html += `<div class="row main-product mx-0">
        <div class="col-8 pl-1 pr-0 mb-2"><p class="mb-0 rtl d-flex"><span class="quantity pl-2">${pli.quantity}&#215;</span><span>${pli.productName}</span></p></div>
        <div class="col-4 pr-1 pl-0 mb-2"><p class="text-left mb-0 main-product-total">${pli.priceTotal.price}</p></div>
    </div>`;
            });
            if (html) {
                $(html).insertAfter('div.row.main-products-title');
            }
        }
        // Totals for main products
        if (data.groupedTotals.main) {
            $('.js-main-products-total').html(data.groupedTotals.main.formatted);
        }

        $('.row.addon-product').remove();
        if (data.groupedItems.addOns) {
            let html = '';
            data.groupedItems.addOns.forEach(function (pli) {
                html += `<div class="row addon-product mx-0">
        <div class="col-8 pl-1 pr-0 mb-2"><p class="mb-0 rtl d-flex"><span class="quantity pl-2">${pli.quantity}&#215;</span><span>${pli.productName}</span></p></div>
        <div class="col-4 pr-1 pl-0 mb-2"><p class="text-left mb-0 addon-product-total">${pli.priceTotal.price}</p></div>
    </div>`;
            });
            if (html) {
                $(html).insertAfter('div.row.addon-products-title');
                $('div.row.no-addons').addClass('d-none');
            }
        }

        // Totals for addOns products
        if (data.groupedTotals.addOns) {
            $('.js-addon-products-total').html(data.groupedTotals.addOns.formatted);
        }

        $('.row.extraRooms-product').remove();
        if (data.groupedItems.extraRooms) {
            let html = '';
            data.groupedItems.extraRooms.forEach(function (pli) {
                html += `<div class="row extraRooms-product mx-0">
        <div class="col-8 pl-1 pr-0 mb-2"><p class="mb-0 rtl d-flex"><span class="quantity pl-2">${pli.quantity}&#215;</span><span>${pli.productName}</span></p></div>
        <div class="col-4 pr-1 pl-0 mb-2"><p class="text-left mb-0 extraRooms-product-total">${pli.priceTotal.price}</p></div>
    </div>`;
            });
            if (html) {
                $(html).insertAfter('div.row.extraRooms-products-title');
                $('div.row.no-extraRooms').addClass('d-none');
            }
        }

        // Totals for addOns products
        if (data.groupedTotals.extraRooms) {
            $('.js-extraRooms-products-total').html(data.groupedTotals.extraRooms.formatted);
        }
    }

    if ($('body').find('.row.addon-product').length == 0) {
        $('div.row.no-addons').removeClass('d-none');
    }

    if ($('body').find('.row.extraRooms-product').length == 0) {
        $('div.row.no-extraRooms').removeClass('d-none');
    }
}

function handleChangeInviteType(e) {
    let $this = $(e.target);
    let val = $this.find('option:checked').val();
    const $colleagueVerificationModal = $('#colleagueVerificationModal');

    if (val === 'specified') {
        $colleagueVerificationModal.find('form').attr('data-mode', '');
        $colleagueVerificationModal.find('form').find('.form-group').removeClass('form-group-filled');
        $colleagueVerificationModal.modal('show');
        setTimeout(function () {
            $colleagueVerificationModal.find('#invite-form-colleagueNo').focus();
        }, 500);
    } else {
        $colleagueVerificationModal.find('input').val('');
        $colleagueVerificationModal.modal('hide');
        $('.colleague-info').addClass('d-none');
        $('body').trigger('product:hilaAddToCart');
    }
}

/**
 * Masks a phone number by replacing all characters except for the first `visibleCharsInStart`
 * and last `visibleCharsInEnd` with asterisks.
 *
 * @param {string} phoneNumber the phone number to mask
 * @returns {string} the masked phone number
 */
function maskPhone(phoneNumber) {
    let visibleCharsInStart = 3;
    let visibleCharsInEnd = 3;
    // eslint-disable-next-line no-param-reassign
    phoneNumber = phoneNumber.substring(0, visibleCharsInStart) + '*'.repeat(phoneNumber.length - visibleCharsInStart - visibleCharsInEnd) + phoneNumber.substring(phoneNumber.length - visibleCharsInEnd);

    return phoneNumber;
}

/**
 * Masks an email address by replacing all characters except for the first
 * `visibleCharsInStart` characters of the local part with asterisks.
 *
 * @param {string} email the email address to mask
 * @returns {string} the masked email address
 */
function maskEmail(email) {
    let visibleCharsInStart = 1;
    let emailParts = email.split('@');

    let maskedPart = email.substring(0, visibleCharsInStart) + '*'.repeat(emailParts[0].length - visibleCharsInStart);

    return maskedPart + email.replace(/[^@]+([@\w\W]+)/g, '$1');
}

function handleSubmitVerificationForm(e) {
    e.preventDefault();
    const $form = $(e.target).closest('form');

    if ($form[0].checkValidity()) {
        $.ajax({
            url: $form.attr('action'),
            method: $form.attr('method'),
            data: $form.serialize(),
            beforeSend: $.spinner().start,
            complete: $.spinner().stop,
            success: function (data) {
                let mode = data && data.success ? 'verification-passed' : 'verification-not-passed';
                if (mode === 'verification-passed') {
                    var email = data.object.email;
                    var mobile = data.object.mobile;
                    var firstName = data.object.firstname;
                    var lastName = data.object.lastname;
                    var $mailInput = $('#invite-form-colleagueEmail');
                    var $mobileInput = $('#invite-form-colleaguePhone');
                    var $firstNameInput = $('#invite-colleagueFirstName');
                    var $lastNameInput = $('#invite-colleagueLastName');

                    if ($mailInput.length > 0 && email) {
                        $mailInput.val(maskEmail(email));
                        $mailInput.data('value', email);
                    } else {
                        $mailInput.attr('placeholder', '');
                    }

                    if ($mobileInput.length > 0 && mobile) {
                        $mobileInput.val(maskPhone(mobile));
                        $mobileInput.data('value', mobile);
                    }

                    if ($firstNameInput.length > 0 && firstName) {
                        $firstNameInput.val(firstName);
                    }

                    if ($lastNameInput.length > 0 && lastName) {
                        $lastNameInput.val(lastName);
                    }
                }

                $form.attr('data-mode', mode);
                $('#invite-form-colleagueEmail').attr('required', 'required');
                $('#invite-form-colleaguePhone').attr('required', 'required');
            },
            error: function () {
                $.spinner().stop();
                $form.attr('data-mode', 'verification-not-passed');
            }
        });
    }

    return false;
}

function saveColleagueData(e) {
    e.preventDefault();
    e.stopPropagation();
    const $saveBtn = $(e.target);
    const $form = $saveBtn.closest('form');

    if ($form[0].checkValidity()) {
        $('.colleague-info').removeClass('d-none');
        $('#invite-colleagueNo').val($('#invite-form-colleagueNo').val());
        $('#invite-colleagueEmail').val($('#invite-form-colleagueEmail').data('value'));
        $('#invite-colleaguePhone').val($('#invite-form-colleaguePhone').data('value'));
        $('.colleague-info #firstName').text($('#invite-colleagueFirstName').val());
        $('.colleague-info #lastName').text($('#invite-colleagueLastName').val());
        $('.colleague-info #colleagueNo').text($('#invite-colleagueNo').val());
        $form.closest('.modal').modal('hide');
        $('body').trigger('product:hilaAddToCart');
    } else {
        $('.colleague-info').addClass('d-none');
    }


    return false;
}

function isInviteRoomTypeSelected() {
    return $('.js-invite-option-select').closest('.selectric-wrapper').find('.selectric:visible').length > 0;
}

function init() {
    $(document)
        .on('change', '.js-invite-option-select', handleChangeInviteType)
        .on('click', 'button.js-colleague-verification', handleSubmitVerificationForm)
        .on('click', 'button.js-save-colleague-data', saveColleagueData)
        .on('click', '.js-related-product-select', function () {
            $('body').trigger('product:hilaAddToCart');
        })
        .on('change', '.js-extra-room-select', function () {
            resetAddonQuantities();
            $('body').trigger('product:hilaAddToCart');
        })
        .on('click', '.js-change-colleague', function () {
            $('.js-invite-option-select').trigger('change');
            let $colleagueNo = $('#invite-form-colleagueNo');
            $colleagueNo.closest('.form-group').removeClass('form-group-filled');
            $colleagueNo.removeClass('filled').removeClass('is-invalid').val('');
        })
        .on('click', '.verification-try-again', function (e) {
            e.preventDefault();
            let $colleagueNo = $('#invite-form-colleagueNo');
            $colleagueNo.closest('.form-group').removeClass('form-group-filled');
            $colleagueNo
                .removeClass('filled')
                .val('');
            $('form[name=colleague-verification-form]').attr('data-mode', '');
        })
        .on('click', '.btn-purchase', function (e) {
            let $roomTypeSelect = $('.select-roomType');
            $roomTypeSelect.trigger('selectric-select');
            scrollAnimate($roomTypeSelect.closest('.form-group'));

            if (isInviteRoomTypeSelected()) {
                let $inviteSelect = $('.js-invite-option-select');
                $inviteSelect.trigger('selectric-select');
                scrollAnimate($inviteSelect.closest('.form-group'));
            }

            if ($('.js-extra-room-container:visible').length > 0) {
                $('.js-extra-room-container:visible').each(function (_, container) {
                    let $container = $(container);
                    let selectedExtraRoom = $container.find('option:checked').val();
                    if (!selectedExtraRoom) {
                        $container.find('.custom-select').trigger('selectric-select');
                    }
                });
            }
            if ($('.attributes div.is-invalid').length > 0) {
                e.preventDefault();
                e.stopPropagation();
            }
        })
        .on('click', '.js-close-invite-modal', function () {
            let colleagueData = getColleagueInviteData();
            if (!colleagueData || (colleagueData.inviteType === 'specified' && !colleagueData.colleagueNo)) {
                location.reload();
            }
        });

    $('body')
        .on('product:afterAddToCart', function (e, data) {
            updateCartTotals(data.cart);
        })
        .on('product:hilaAddToCart', debounce(function () {
            var promiseClearCart = new Promise(function (resolve, reject) {
                $.ajax({
                    url: window.app.URL.CLEAR_BASKET,
                    method: 'GET',
                    success: function () {
                        resolve(true);
                    },
                    error: function () {
                        reject(true);
                    }
                });
            });
            promiseClearCart.then(function () {
                $.spinner().stop();
                $('button.add-to-cart, button.add-to-cart-global').trigger('click');
            }, function () {
            });
        }, 300))
        .on('hidden.bs.modal', '#colleagueVerificationModal', function () {
            $('.colleague-info').removeClass('d-none');
        });
    $('body').on('click', '.product-extra-room .product-add-extra-room', function (e) {
        e.preventDefault();
        let $firstExtraRoom = $('.js-extra-room-container:first');
        let $newExtraRoomHtml = $firstExtraRoom.clone();
        $newExtraRoomHtml.removeClass('d-none').addClass('d-flex');
        let originalSelect = $newExtraRoomHtml.find('.js-extra-room-select.custom-select').clone();
        $newExtraRoomHtml.find('.selectric-js-extra-room-select').replaceWith(originalSelect);
        $newExtraRoomHtml.insertBefore($(this).parent());
        $newExtraRoomHtml.find('select').removeClass('d-none');
        $newExtraRoomHtml.find('.js-extra-room-select').selectric('refresh');

        if ($('.js-extra-room-container:visible').length >= 3) {
            $('.product-add-extra-room').parent().addClass('d-none');
        } else {
            $('.product-add-extra-room').parent().removeClass('d-none');
        }
    }).on('click', '.product-extra-room .product-remove-extra-room', function (e) {
        e.preventDefault();
        resetAddonQuantities();
        if ($('.js-extra-room-container').length > 1) {
            $(this).closest('.js-extra-room-container').remove();
        } else {
            $('.extra-room-container').removeClass('d-flex').addClass('d-none');
            $('.js-extra-room-select').prop('selectedIndex', 0).selectric('refresh');
        }
        if ($('.js-extra-room-container:visible').length >= 3) {
            $('.product-add-extra-room').parent().addClass('d-none');
        } else {
            $('.product-add-extra-room').parent().removeClass('d-none');
        }
        $('.js-extra-room-select:visible:first').trigger('change');
    });

    let isAcceptingInviteFlow = $('.js-accepting-invite-flow');
    if (isAcceptingInviteFlow && isAcceptingInviteFlow.length > 0) {
        $('.select-roomType').trigger('change');
    }
}

function resetAddonQuantities() {
    $('.js-related-product-select .quantity-select').val(0);
    $('.addons-qty-input').val(0);
    $('.total-amount-of-addon    .total-count').text(0);
    $('.js-related-product-select').prop('checked', false).attr('checked', false);
    let $firstAddon = $($('.js-related-products-container .product-info').get(0));
    $firstAddon.find('.addons-qty-input').val(0);
    $('.toddlers-actions .addons-qty-input').val(0);
    $('.product-option .options-select').val($('.product-option .options-select option:first').val()).selectric('refresh');

    $('.addon-actions  .increase-qty, .product-option .increase-qty').removeClass('qty-disabled');
    $('.addon-actions  .increase-qty span, .product-option .increase-qty span').removeClass('disabled');

    $('.addon-actions  .decrease-qty, .product-option .decrease-qty').addClass('qty-disabled');
    $('.addon-actions  .decrease-qty span, .product-option .decrease-qty span').addClass('disabled');

    $('.total-amount-text').addClass('total-amount-text-empty');
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        let callback = function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            if ($productContainer.find('.related-products-container').length > 0) {
                $productContainer.find('.related-products-container').removeClass('d-none');
            }

            attributeSelect(e.currentTarget.value, $productContainer, $(this).hasClass('options-select'));
        };
        $(document).on('change', '.options-select', debounce(callback, 500));
        $(document).on('change', 'select[class*="select-"]', function (e) {
            resetAddonQuantities();
            callback.call(this, e);
        });
    },

    availability: function () {
        let callback = debounce(function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer, null);
            }
        }, 500);
        $(document).on('change', '.quantity-select', callback);
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var relatedIDsObj;
            var extraRoomIDsObj;
            var setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];
                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val() || 1,
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            } else {
                var relatedIDs = [];
                $('.js-related-products-container .card.product-info').each(function () {
                    if ($(this).find('.js-related-product-select:checked').length > 0) {
                        relatedIDs.push({
                            pid: $(this).find('.product-id').val(),
                            qty: $(this).find('.quantity-select').val() || 1
                        });
                    }
                });
                relatedIDsObj = relatedIDs.length > 0 ? JSON.stringify(relatedIDs) : null;

                let extraRoomIDs = [];
                $('select.js-extra-room-select').find('option:checked').each(function (_, option) {
                    let extraRoomPID = $(option).val();
                    if (extraRoomPID) {
                        extraRoomIDs.push({
                            pid: extraRoomPID,
                            qty: 1
                        });
                    }
                });

                extraRoomIDsObj = extraRoomIDs.length > 0 ? JSON.stringify(extraRoomIDs) : null;
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                relatedIDsObj: relatedIDsObj,
                extraRoomIDsObj: extraRoomIDsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            const inviteColleagueData = getColleagueInviteData();
            if (inviteColleagueData) {
                form = Object.assign(form, inviteColleagueData);
            }

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            let queryParams = utils.getQueryStringParams(window.location.search);
            if (queryParams && Object.keys(queryParams).length > 0 && queryParams.inviteData) {
                form.inviteData = queryParams.inviteData;
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    beforeSend: function () {
                        $('.js-pdp-extra-form .invalid-feedback').text('');
                        $('.js-pdp-extra-form .is-invalid').removeClass('is-invalid');
                    },
                    success: function (data) {
                        $('.pdp-totals').attr('data-mode', 'empty');
                        $('.pdp-totals .btn-purchase a').addClass('disabled');
                        if (!data.error) {
                            $('body').trigger('product:afterAddToCart', data);
                        } else if (data.fields && data.fields.length > 0) {
                            data.fields.forEach(function (errors, formIndex) {
                                let errorKeys = Object.keys(errors);
                                errorKeys.forEach(function (fieldName) {
                                    let $input = $($('.js-pdp-extra-form').get(formIndex)).find(`input[name$='${fieldName}']`);
                                    let $formGroup = $input.parents('.form-group');
                                    $formGroup.find('.invalid-feedback').text(errors[fieldName]);
                                    $input.addClass('is-invalid');
                                });
                            });
                        }
                    },
                    complete: $.spinner().stop,
                    error: function (err) {
                        if (!err.responseJSON.loggedin) {
                            $('body').trigger('showLoginModal');
                        }
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                    + '<div class="selected-pid row" '
                    + 'data-pid="' + pid + '"'
                    + 'data-qty="' + submittedQty + '"'
                    + 'data-optionID="' + (optionID || '') + '"'
                    + 'data-option-selected-value="' + (valueId || '') + '"'
                    + '>'
                    + '<div class="col-sm-11 col-9 bonus-product-name" >'
                    + $choiceOfBonusProduct.find('.product-name').html()
                    + '</div>'
                    + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                    + '</div>'
                ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    init: init
};
