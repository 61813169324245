'use strict';

function createNotification(message, success) {
    var messageType = success ? 'alert-danger' : 'alert-success';
    if ($('.notification-messages').length === 0) {
        $('body').append('<div class="notification-messages"></div>');
    }

    $('.notification-messages').append(`<div class="alert ${messageType} notification-alert text-center" role="alert">${message}</div>`);

    setTimeout(function () {
        $('.notification-messages').remove();
    }, 5000);
}

module.exports = {
    createSuccessNotification: function (message) {
        createNotification(message, false);
    },

    createErrorNotification: function (message) {
        createNotification(message, true);
    }
};
